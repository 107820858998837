import React, { FC, useEffect, useState} from "react";
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";

import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css';

import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import StringFilter from '@inovua/reactdatagrid-community/StringFilter';

import moment from 'moment';
import { ordenarLista, ordenarListaPorMultiplesCampos } from "../../../pages/scheduler/redux/SchedulerCRUD";
import { getWorkOrders } from "../redux/WorkOrderCRUD";
import { useTranslation } from "react-i18next";
window.moment = moment;

const gridStyle = { minHeight: 600, height: '85vh' };

const WorkOrderReport: FC = () => {
    const { t } = useTranslation();
    const [filas, setFilas] = useState<any[]>([]);
    const [lines, setLines] = useState<any[]>([]);
    const [teams, setTeams] = useState<any[]>([]);
    const [issueCodes, setIssueCodes] = useState<any[]>([]);
    const states = [
        { id: 'PROGRAMADA', label: t('SCHEDULED') },
        { id: 'FINALIZADA', label: t('FINISHED') },
        { id: 'CANCELADA', label: t('CANCELED') }
    ];
    const _export = React.useRef(null);

    useEffect(() => {
        getWorkOrders()
        .then((res: any) => {
            const data: any[] = res.data;
            data.forEach((o: any) => {
                o.team_name = o.team?.name;
                o.issue_number = o.issue?.number;
                o.issuecode_name = o.issue?.issueCode?.name;
                o.revision_number = o.issue?.revisions[0]?.revision_number;
                o.observations_resolution = o.issue?.revisions[0]?.observations_resolution;
            });

            const teamsOptions = Array.from(new Set(data.map((r: any) => r.team_name))).map(d => ({ label: d, id: d }));
            const teams = ordenarLista('label', teamsOptions, 'asc');
            setTeams(teams);

            const linesOptions = Array.from(new Set(data.map((r: any) => r.line_name))).map(d => ({ label: d, id: d }));
            const lines = ordenarLista('label', linesOptions, 'asc');
            setLines(lines);

            const codesOptions = Array.from(new Set(data.map((r: any) => r.issuecode_name))).map(d => ({ label: d, id: d }));
            const issueCodes = ordenarLista('label', codesOptions, 'asc');
            setIssueCodes(issueCodes);

            const filas = ordenarListaPorMultiplesCampos('line_name', 'tower_name', data);
            setFilas(filas);
        })
        .catch(err => console.log(err))
    }, [])

    const exportar = (event) => {
        event.stopPropagation();
        event.preventDefault();
        if (_export.current !== null) {
            _export.current.save();
        }
    }

    const columns = [
        { name: 'number', header: t('numberWO'), defaultFlex: 1, filterEditor: StringFilter },
        { name: 'schedule_date', header: t('dateStartScheduled'), defaultFlex: 1, dateFormat: 'DD/MM/YYYY', type: 'date', filterEditor: DateFilter, 
            filterEditorProps: () => {
                return {
                    dateFormat: 'DD/MM/YYYY',
                }
            },
            sort: (p1: any, p2: any) => p1 && p2 ? p1.localeCompare(p2) : '',
            render: ({ value, cellProps: { dateFormat } }) => value ? moment(value).format(dateFormat) : '',
        },
        { name: 'end_date_scheduled', header: t('dateEndScheduled'), defaultFlex: 1, dateFormat: 'DD/MM/YYYY', type: 'date', filterEditor: DateFilter,
            filterEditorProps: () => {
                return {
                    dateFormat: 'DD/MM/YYYY',
                }
            },
            sort: (p1: any, p2: any) => p1 && p2 ? p1.localeCompare(p2) : '',
            render: ({ value, cellProps: { dateFormat } }) => value ? moment(value).format(dateFormat) : '',
        },
        { name: 'team_name', header: t('team'), defaultFlex: 1, filterEditor: SelectFilter,
            filterEditorProps: {
                placeholder: t('all'),
                dataSource: teams,
                multiple: true,
                wrapMultiple: false
            },
        },
        { name: 'line_name', header: t('line'), defaultFlex: 1, filterEditor: SelectFilter,
            filterEditorProps: {
                placeholder: t('all'),
                dataSource: lines,
                multiple: true,
                wrapMultiple: false
            },
        },
        { name: 'tower_name', header: t('structure'), defaultFlex: 1, type: 'text', filterEditor: StringFilter },
        { name: 'issuecode_name', header: t('incidentCode'), defaultFlex: 1, filterEditor: SelectFilter,
            filterEditorProps: {
                placeholder: t('all'),
                dataSource: issueCodes,
                multiple: true,
                wrapMultiple: false
            },
        },
        { name: 'issue_number', header: t('incidentNo'), defaultFlex: 1, type: 'number', filterEditor: NumberFilter },
        { name: 'revision_number', header: t('revisionNo'), defaultFlex: 1, type: 'number', filterEditor: NumberFilter },
        { name: 'status', header: t('state'), defaultFlex: 1, filterEditor: SelectFilter,
            filterEditorProps: {
                placeholder: t('all'),
                dataSource: states,
                multiple: true,
                wrapMultiple: false
            },
        },
        { name: 'end_date', header: t('repairDate'), defaultFlex: 1, dateFormat: 'DD/MM/YYYY', type: 'date', filterEditor: DateFilter, 
            filterEditorProps: () => {
                return {
                    dateFormat: 'DD/MM/YYYY',
                }
            },
            sort: (p1: any, p2: any) => p1 && p2 ? p1.localeCompare(p2) : '',
            render: ({ value, cellProps: { dateFormat } }) => value ? moment(value).format(dateFormat) : '',
        },
        { name: 'observations_resolution', header: t('resolutionObs'), defaultFlex: 1, type: 'text', filterEditor: StringFilter },
    ];

    const filters = [
        { name: 'number', operator: 'eq', type: 'string', value: undefined },
        { name: 'schedule_date', operator: 'eq', type: 'date', value: undefined },
        { name: 'end_date_scheduled', operator: 'eq', type: 'date', value: undefined },
        { name: 'team_name', operator: 'inlist', type: 'select', value: undefined },
        { name: 'line_name', operator: 'inlist', type: 'select', value: undefined },
        { name: 'status', operator: 'inlist', type: 'select', value: undefined },
        { name: 'tower_name', operator: 'contains', type: 'string', value: undefined },
        { name: 'issuecode_name', operator: 'inlist', type: 'select', value: undefined },
        { name: 'issue_number', operator: 'eq', type: 'number', value: undefined },
        { name: 'revision_number', operator: 'eq', type: 'number', value: undefined },
        { name: 'end_date', operator: 'eq', type: 'date', value: undefined },
        { name: 'observations_resolution', operator: 'contains', type: 'string', value: undefined },

    ];


    const renderTable = () => {
       return(
        <ReactDataGrid
            idProperty="id"
            style={gridStyle}
            columns={columns}
            dataSource={filas}
            rowHeight={25}
            defaultFilterValue={filters}
        />
       )
    }

    return(
        <div className='card'>
            <div className='card-body'>
            <h3>{t('reportWO')}</h3>
                <form action="" method="post" className="form-horizontal">
                    <div className="d-flex flex-column align-items-end justify-content-end">
                        <button className="btn btn-success btn-sm w-200px" onClick={(event) => exportar(event)}>
                            <i className="fa fa-file-excel"></i>{" "}
                            {t('exportExcel')}
                        </button>
                    </div>
                    {renderTable()}
                    <React.Fragment>
                        <ExcelExport
                            data={filas}
                            fileName="Detalle_OT.xlsx"
                            ref={_export}>
                            <ExcelExportColumn field={"number"} title={t('numberWO')}/>
                                <ExcelExportColumn field={"schedule_date"} title={t('startDateSchedule')}/>
                                <ExcelExportColumn field={"end_date_scheduled"} title={t('endDateSchedule')}/>
                                <ExcelExportColumn field={"team_name"} title={t('team')}/>    
                                <ExcelExportColumn field={"line_name"} title={t('line')}/>
                                <ExcelExportColumn field={"tower_name"} title={t('structure')} />
                                <ExcelExportColumn field={"issuecode_name"} title={t('incidentCode')} />
                                <ExcelExportColumn field={"issue_number"} title={t('incidentNumber')} />
                                <ExcelExportColumn field={"revision_number"} title={t('revisionNumber')} />
                                <ExcelExportColumn field={"status"} title={t('state')} />
                                <ExcelExportColumn field={"end_date"} title={t('resolutionDate')} />
                                <ExcelExportColumn field={"observations_resolution"} title={t('resolutionObservations')} />
                        </ExcelExport>
                    </React.Fragment>
                </form>
            </div>
        </div>
    )
}
 
export { WorkOrderReport };
