import axios from 'axios'

const { REACT_APP_API_URL } = process.env
export const GET_TEAMS = `${REACT_APP_API_URL}/team-member/team/all`
export const FIND_ORDERS = `${REACT_APP_API_URL}/order/find`

export function getTeams() {
  return axios.get<{result: any}>(GET_TEAMS)
}

export function addTeam(teamData: any) {
  return axios.post<{result: any}>(`${REACT_APP_API_URL}/team-member/team/save`, teamData)
}

export function removeTeam(teamId: number) {
  return axios.delete<{result: any}>(`${REACT_APP_API_URL}/team-member/team/` + teamId)
}

export function findOrders(teamid?: string, status?: Array<String>) {
  return axios.get<{result: any}>(FIND_ORDERS, { 
    params: {
      team: teamid,
      status
    }
  })
}

export const ordenarLista = (campo: string, lista: any[], sentido: string) => {
  var comparer:any = function (a: any, b: any) {
    if (sentido === 'asc') {
      return (a[campo] > b[campo]) ? 1 : -1;
    }
    else if (sentido === 'desc') {
      return (a[campo] < b[campo]) ? 1 : -1;
    }
  }
  lista.sort(comparer);
  return lista;
}

export function ordenarListaPorMultiplesCampos(campo1: string, campo2: string, lista: any[]) {
  var comparer = function (a: any, b: any) {
    if(a[campo1] && b[campo1] && a[campo2] && b[campo2]) {    
      return a[campo1].localeCompare(b[campo1]) || a[campo2].localeCompare(b[campo2]);
    }
    return 0;
  }

  lista.sort(comparer);
  return lista;
}
