/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect} from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import './scheduler.css';
import { findOrders, getTeams } from './redux/SchedulerCRUD';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';

require('moment/locale/es.js');
require('moment/locale/en-gb.js');
require('moment/locale/pt-br.js');

const localizer = momentLocalizer(moment) // or globalizeLocalizer

const EventComponent = (props: any) => (
  <React.Fragment>
      <div className={'card mb-1 border border-gray-500 rounded'}>
          <div className="card-header wrap-word p-1" style={{minHeight: 'unset', backgroundColor: '#f0f3f5'}}>
            <div>{props.event.line} | <strong>{props.event.tower}</strong></div>
          </div>
          <div className={'card-body p-0 bg-' + props.event.color}>
          <div className="wrap-word">
              <p className="m-0 p-2" style={{ fontWeight: 'bold' }}>{props.event.issue}</p>
          </div>
          </div>
      </div>
  </React.Fragment>
)

type Props = {
  orders?: any[]
}

const SchedulerPage: FC<Props> = ({ orders }) => {
  const { t } = useTranslation();
  const [lang] = React.useState('es');
  const [eventos, setEventos] = React.useState<any[]>([]);

  useEffect(() => {
    const eventList: any[] = [];
    if(orders) {
      orders.forEach((o: any) => {
        eventList.push({
            start: moment(o.schedule_date, 'YYYY-MM-DD'), //start off para el dia completo .startOf('day')
            end: o.end_date_scheduled ? moment(o.end_date_scheduled, 'YYYY-MM-DD').endOf('day') : moment(o.schedule_date, 'YYYY-MM-DD').endOf('day'), //end off para dia completo
            title: `${o.line_name} | ${o.tower_name} | ${o.issue?.issueCode?.name}`,
            id: o.id,
            allDay: true,
            line: o.line_name,
            tower: o.tower_name,
            issue: o.issue?.issueCode?.name,
            color: o.issue?.issueCode?.level_risk_color
        })
      });
      setEventos(eventList);
    }
  }, [orders])

  return(
    <>
      <Calendar
        selectable={true}
        localizer={localizer}
        components={{
            event: EventComponent
        }}
        events={eventos}
        startAccessor="start"
        endAccessor="end"
        defaultView="week"
        culture={(lang === "es" ? "es" : (lang === "en" ? "en-GB" : "pt-BR"))}
        views={['month', 'week', 'day']}
        style={{ height: '81vh'}}
        
        messages={{
          next: t("next"),
          previous: t("previous"),
          today: t("today"),
          month: t("month"),
          week: t("week"),
          day: t("day"),
          date: t("date"),
          time: t("hour"),
          event: t("event"),
          noEventsInRange: t('noEventsInRange'),
        }}
      />
      </>
  )
}

const SchedulerWrapper: FC = () => {
  const [teams, setTeams] = React.useState([]);
  const [teamSelected, setTeamSelected] = React.useState('');
  const [orders, setOrders] = React.useState([]);

  useEffect(() => {
    getTeams().then((response: any) => {
      setTeams(response.data);
      setTeamSelected(response.data[0].id);
    })
  }, [])

  useEffect(() => {
    if(teamSelected) {
      findOrders(teamSelected, ['PROGRAMADA']).then((response: any) => {
        setOrders(response.data)
      })
    }
  }, [teamSelected])

  return (
    <div className='px-5'>
      <div className='d-flex flex-wrap flex-stack'>
        <h3 className='fw-bolder my-2'>
          {t('schedule')}
        </h3>

        <div className='d-flex align-items-end my-2'>
          <div className='w-300px'>
            <select
              name='status'
              data-control='select2'
              data-hide-search='true'
              className='form-select form-select-white form-select-sm'
              defaultValue={teamSelected}
              onChange={(e) => setTeamSelected(e.target.value)}
            >
              {
                teams.map((team: any) => (
                  <option key={team.id} value={team.id}>{team.name}</option>
                ))
              }
            </select>
          </div>
        </div>
      </div>
      <SchedulerPage 
        orders={orders}
      />
    </div>
  )
}

export {SchedulerWrapper}
