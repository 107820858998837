import React, {FC, useCallback, useDebugValue, useMemo, useState} from 'react'
import {getTeams, ordenarLista, ordenarListaPorMultiplesCampos} from '../../../pages/scheduler/redux/SchedulerCRUD'
import {getIssuesPending, saveWorkOrders, updateIssueStatusForId} from '../redux/WorkOrderCRUD'
import ReactDataGrid from '@inovua/reactdatagrid-community'
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter'
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter'
import DateFilter from '@inovua/reactdatagrid-community/DateFilter'
import StringFilter from '@inovua/reactdatagrid-community/StringFilter'
import moment from 'moment'
import * as Yup from 'yup'
import {Formik} from 'formik'
import {useTranslation} from 'react-i18next'
import LoadingOverlay from 'react-loading-overlay-ts'
import {mostrarError, mostrarExito, mostrarWarning} from '../../../../core/EmisorMensajes'
import {Modal} from 'react-bootstrap-v5'

const CreateWorkOrders: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [teams, setTeams] = useState<any[]>([])
  const [filasIssues, setFilasIssues] = useState<any[]>([])
  const [filasIssuesSeleccionadas, setFilasIssuesSeleccionadas] = useState<any[]>([])
  const [seleccionFilasIssues, setSeleccionFilasIssues] = useState<any[]>([])
  const [selectUser, setDataUser] = useState<any[]>([])

  const [filasEnProceso, setFilasEnProceso] = useState<any[]>([])
  const [filasEnProcesoSeleccionadas, setFilasEnProcesoSeleccionadas] = useState<any[]>([])
  const [seleccionFilasEnProceso, setSeleccionFilasEnProceso] = useState<any[]>([])

  const [lines, setLines] = useState<any[]>([])
  const [issueCodes, setIssueCodes] = useState<any[]>([])

  const [loading, setLoading] = useState('')
  const [issueStatus, setIssueStatus] = useState<any[]>([])
  const {t} = useTranslation()

  const [showModal, setShowModal] = useState(false)

  const [selectedUserInfo, setSelectedUserInfo] = useState(null)


  useMemo(() => {
    getTeams()
      .then((res: any) => {
        if (res.data) {
          res.data.forEach((team: any) => team.descripcion = team.name)
          const teams: any[] = ordenarLista('descripcion', res.data, 'asc')
          setTeams(teams)
        }
      })
  }, [])

  useMemo(() => {
    debugger;
    setLoading('Cargando incidencias')
    getIssuesPending().then((response: any) => {
      const data = response.data
      data.forEach((i: any) => i.seleccionada = false)

      const filas = ordenarListaPorMultiplesCampos('line_name', 'tower_name', data)
      setFilasIssues(filas)

      const linesOptions = Array.from(new Set(data.map((r: any) => r.line_name))).map(d => ({label: d, id: d}))
      const lines = ordenarLista('label', linesOptions, 'asc')
      setLines(lines)

      const codesOptions = Array.from(new Set(data.map((r: any) => r.issue_code_description))).map(d => ({
        label: d,
        id: d,
      }))
      const issueCodes = ordenarLista('label', codesOptions, 'asc')
      setIssueCodes(issueCodes)

      // const statusOptions = Array.from(new Set(data.map((r: any) => r.issue_status))).map(d => ({ label: d, id: d }));
      const statusOptions = Array.from(new Set(data.map((r: any) => r.issue_status))).map(d => ({
        label: d === 'VALIDATE_REPAIR' ? t('Validar reparación') : t('No reparada'),
        id: d,
      }))
      const issueStatus = ordenarLista('label', statusOptions, 'asc')
      setIssueStatus(issueStatus)

      setLoading('')
    })
    // eslint-disable-next-line
  }, [])

  const crearOTSchema = Yup.object().shape({
    schedule_date: Yup.date()
      .required(t('campoRequerido')),
    end_date_scheduled: Yup.date()
      .when('schedule_date',
        (schedule_date, yup) => schedule_date && yup.min(schedule_date, t('endDateBeforeStartDate')))
      .required(t('campoRequerido')),
    team_id: Yup.string().required(t('campoRequerido')),
  })

  const COLUMNAS = [
    {
      name: 'line_name', header: t('line'), defaultFlex: 1, filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: t('all'),
        dataSource: lines,
        multiple: true,
        wrapMultiple: false,
      },
    },
    {name: 'tower_name', header: t('structure'), defaultFlex: 1, type: 'text', filterEditor: StringFilter},
    {
      name: 'issue_code_description', header: t('incidentCode'), defaultFlex: 1, filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: t('all'),
        dataSource: issueCodes,
        multiple: true,
        wrapMultiple: false,
      },
    },
    {name: 'issue_observations', header: t('observations'), defaultFlex: 1, type: 'text', filterEditor: StringFilter},
    {name: 'issue_number', header: t('incidentNumber'), defaultFlex: 1, type: 'number', filterEditor: NumberFilter},
    {name: 'rev_number', header: t('revisionNumber'), defaultFlex: 1, type: 'number', filterEditor: NumberFilter},
    {
      name: 'rev_date',
      header: t('revisionDate'),
      defaultFlex: 1,
      dateFormat: 'DD/MM/YYYY',
      type: 'date',
      filterEditor: DateFilter,
      filterEditorProps: () => {
        return {
          dateFormat: 'DD/MM/YYYY',
        }
      },
      sort: (p1: any, p2: any) => p1 && p2 ? p1.localeCompare(p2) : '',
      render: (cell: any) => cell.value ? moment(cell.value).format(cell.cellProps.dateFormat) : '',
    },
    {
      name: 'issue_status',
      header: t('Estado'),
      defaultFlex: 1,
      type: 'text',
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: t('all'),
        dataSource: issueStatus, // Asegúrate de que issueStatus contenga las opciones de estado
        multiple: false,
        wrapMultiple: false,
      },
      render: ({value}) => {
        return value === 'VALIDATE_REPAIR' ? t('Validar reparación') : t('No reparada')
      },
      // Agrega más propiedades si es necesario para adaptar la columna
    },
  ]

  const onSelectionFilasIssuesChange = useCallback(({selected}) => {
    debugger;

    const seleccionadas = Object.values(selected)
    setSeleccionFilasIssues(seleccionadas)
    setFilasIssuesSeleccionadas(selected)

    const selectedUser = seleccionadas[seleccionadas.length - 1]
//teams.filter(team => team.resolved_in_review !== true)
    setSelectedUserInfo(selectedUser)
    console.log(selectedUser);
    setShowModal(!!(selectedUser as {userDetails: any, issue_status: string})?.userDetails &&
      'VALIDATE_REPAIR' === selectedUser['issue_status'])
    setIsLoading(false);

  }, [])


  const onSelectionFilasEnProcesoChange = useCallback(({selected}) => {
    const seleccionadas = Object.values(selected)
    setSeleccionFilasEnProceso(seleccionadas)
    setFilasEnProcesoSeleccionadas(selected)
  }, [])

  const agregarSeleccionadas = () => {
    debugger;
    let nuevasFilas = [...filasIssues]
    const nuevasFilasEnProceso = [...filasEnProceso]
    seleccionFilasIssues.forEach(fila => {
      const idx = nuevasFilas.findIndex(f => fila.id === f.id)

      if (idx !== -1) {
        nuevasFilasEnProceso.push(nuevasFilas[idx])
        setFilasEnProceso(nuevasFilasEnProceso)

        nuevasFilas.splice(idx, 1)
      }
    })
    setFilasIssues(nuevasFilas)
    setSeleccionFilasIssues([])
    setFilasIssuesSeleccionadas([])
  }

  const quitarSeleccionadas = () => {
    let nuevasFilasEnProceso = [...filasEnProceso]
    let nuevasFilas = [...filasIssues]
    seleccionFilasEnProceso.forEach(fila => {
      const idx = nuevasFilasEnProceso.findIndex(f => fila.id === f.id)

      if (idx !== -1) {
        nuevasFilas.push(nuevasFilasEnProceso[idx])
        setFilasIssues(nuevasFilas)

        nuevasFilasEnProceso.splice(idx, 1)
      }
    })
    setFilasEnProceso(nuevasFilasEnProceso)
    setSeleccionFilasEnProceso([])
    setFilasEnProcesoSeleccionadas([])
  }
  const createAndCloseWo = async () => {
    setIsLoading(true);

    try {
      const creation_date = new Date(); // Store the date to avoid multiple calls
      const schedule_date = new Date();
      const end_date_scheduled = new Date();

      const data = {
        creation_date,
        schedule_date,
        end_date_scheduled,
        issue_id: selectedUserInfo.id,
        team_id: selectedUserInfo.boss.id,
        line_name: selectedUserInfo.line_name,
        tower_name: selectedUserInfo.tower_name,
      };

      const body2 = {
        data: [data],
        jefeCuadrilla: selectedUserInfo.boss.manager_name,
        email: selectedUserInfo.boss.manager_email,
      };

      const response = await saveWorkOrders(body2);

      if (!response) {
        throw new Error("No response received from saveWorkOrders"); // Explicitly throw an error
      }

      if (response.status === 200) {
        setLoading(''); // Use false for clarity, or an empty string if that's your convention
        mostrarExito(t('changesApplied'));

        // More efficient filtering:  Create a new array instead of modifying in place if possible
        const filasUpdate = filasIssues.filter((item) => item.id !== selectedUserInfo.id);
        setFilasIssues(filasUpdate);
debugger;
        setShowModal(false);
      } else if (response.status === 201) {  //Check for other success codes like 201
        setLoading('');
        mostrarExito(t('workOrderCreated')); // Or a more appropriate message
        const filasUpdate = filasIssues.filter((item) => item.id !== selectedUserInfo.id);
        setFilasIssues(filasUpdate);
        setShowModal(false);
      } else {
        // Handle different error codes more specifically
        console.error("Error saving work order:", response.status, response.data); // Log the error for debugging
        setLoading('');
        mostrarError(t('errorSavingWorkOrder')); // Display a user-friendly error message
        // Optionally, you could provide more details to the user based on the response.data
      }
    } catch (error) {
      console.error("Error in createAndCloseWo:", error); // Log the error
      setLoading('');
      mostrarError(t('errorSavingWorkOrder')); // Generic error message, or customize as needed
    }
  }

  const createAndCloseWo2 = async () => {
    setIsLoading(true);
    const data = {
      creation_date: new Date(),
      schedule_date: new Date(),
      end_date_scheduled: new Date(),
      issue_id: selectedUserInfo.id,
      team_id: selectedUserInfo.boss.id,
      line_name: selectedUserInfo.line_name,
      tower_name: selectedUserInfo.tower_name,
    }
    const body2 = {
      data: [data],
      jefeCuadrilla: selectedUserInfo.boss.manager_name,
      email: selectedUserInfo.boss.manager_email,
    }

    const response = await saveWorkOrders(body2)
    if (!response) {
      throw new Error("No response received from saveWorkOrders"); // Explicitly throw an error
    }
    if (response) {
      if (response.status === 200) {
        setLoading('')
        mostrarExito(t('changesApplied'))
        let filas = filasIssues
        let filasUpdate = filas.filter((item) => item.id !== selectedUserInfo.id)

        setFilasIssues(filasUpdate)
      }
    }
    setShowModal(false)

  }

  const reloadIssueStatus = async (data) => {
    setIsLoading(false);
    let filas = filasIssues
    filas.map((item) => {
      if (item.id === data.issue_id) {
        item.issue_status = 'NOT_REPAIRED'
      }
    })
    setFilasIssues(filas)
    setShowModal(false)
  }
  const updateIssueStatus = async () => {
    setIsLoading(true);
    const data = {
      creation_date: new Date(),
      schedule_date: new Date(),
      end_date_scheduled: new Date(),
      issue_id: selectedUserInfo.id,
      issue_status: 'NOT_REPAIRED',

    }

    // limpiarIssuesTrabajadas();
    const body = {issues: [data]}
    const response = await updateIssueStatusForId(body)
    if (response) {
      if (response.status === 200) {
        setLoading('')
        mostrarExito(t('changesApplied'))
        reloadIssueStatus(data)
      }
      setShowModal(false)
    }
  }
  const crearOTs = async (valuesForm: any) => {
    if (!filasEnProceso.length) {
      mostrarWarning(t('mustSelectAnIncident'))
      return
    }

    setLoading('Creando órdenes de trabajo')
    let procesadas = []
    debugger;
    for (let index = 0; index < filasEnProceso.length; index++) {
      try {
        const fila = filasEnProceso[index]
        const data = {
          creation_date: new Date(),
          schedule_date: valuesForm.schedule_date,
          end_date_scheduled: valuesForm.end_date_scheduled,
          issue_id: fila.id,
          team_id: valuesForm.team_id,
          line_name: fila.line_name,
          tower_name: fila.tower_name,
        }

        procesadas.push(data)
      } catch (err) {
        setLoading('')
        mostrarError(err)
      }
    }

    if (procesadas.length) {
      const team_id = valuesForm.team_id
      const team = teams.filter(t => t.id === parseInt(team_id))[0]

      if (team) {
        const body = {
          data: procesadas,
          jefeCuadrilla: team.manager_name,
          email: team.manager_email,
        }
        // console.log(body);
        const response = await saveWorkOrders(body)
        if (response) {
          if (response.status === 200) {
            setLoading('')
            mostrarExito(t('changesApplied'))
            limpiarIssuesTrabajadas()
          }
        }
      }
    }
  }

  const limpiarIssuesTrabajadas = () => {
    debugger;
    let filas = filasIssues
    filasEnProceso.forEach(fila => {
      const idx = filas.findIndex(f => fila.id === f.id)
      filas.splice(idx, 1)
    })
    setFilasIssues(filas)
    setFilasEnProceso([])
  }

  const rowClassName = ({data}) => {
    return data.issue_status === 'VALIDATE_REPAIR' ? 'fila-reparacion-validar' : ''
  }

  return (
    <>
      {console.log('rendering...')}

      <LoadingOverlay active={loading !== ''} spinner text={loading + '...'}>
        <div className="card">
          <div className="card-body">
            {
              <>
                <React.Fragment>
                  <h3>{t('generationWO')}</h3>
                  <ReactDataGrid
                    idProperty="id"
                    style={{minHeight: 150, height: '35vh'}}
                    columns={COLUMNAS}
                    dataSource={filasIssues}
                    rowHeight={25}
                    defaultFilterValue={[
                      {name: 'line_name', operator: 'inlist', type: 'select', value: undefined},
                      {name: 'tower_name', operator: 'contains', type: 'string', value: undefined},
                      {name: 'issue_code_description', operator: 'inlist', type: 'select', value: undefined},
                      {name: 'issue_number', operator: 'eq', type: 'number', value: undefined},
                      {name: 'issue_observations', operator: 'contains', type: 'string', value: undefined},
                      {name: 'rev_number', operator: 'eq', type: 'number', value: undefined},
                      {name: 'rev_date', operator: 'eq', type: 'date', value: undefined},
                      {name: 'issue_status', operator: 'contains', type: 'string', value: undefined},
                    ]}
                    selected={filasIssuesSeleccionadas}
                    checkboxColumn
                    onSelectionChange={onSelectionFilasIssuesChange}
                    rowClassName={rowClassName}

                  />
                  <div className="d-flex align-items-center justify-content-center my-2">
                    <button className="btn btn-success btn-sm" data-bs-toggle="tooltip"
                            onClick={() => agregarSeleccionadas()}>
                      <i className="fas fa-angle-double-down"></i>
                      {t('sendSelectedIncidentsList')}
                    </button>
                    <button className="btn btn-danger btn-sm mx-3" data-bs-toggle="tooltip"
                            onClick={() => quitarSeleccionadas()}>
                      <i className="fas fa-angle-double-up"></i>
                      {t('removeFromListSelected')}
                    </button>
                  </div>
                </React.Fragment>

                <React.Fragment>
                  <ReactDataGrid
                    idProperty="id"
                    style={{minHeight: 150, height: '25vh'}}
                    columns={COLUMNAS}
                    dataSource={filasEnProceso}
                    rowHeight={25}
                    selected={filasEnProcesoSeleccionadas}
                    checkboxColumn
                    onSelectionChange={onSelectionFilasEnProcesoChange}
                    rowClassName={rowClassName}
                  />
                </React.Fragment>

                <React.Fragment>
                  <Formik
                    initialValues={{schedule_date: '', end_date_scheduled: '', team_id: ''}}
                    validationSchema={crearOTSchema}
                    onSubmit={(values, {setSubmitting}) => {


                      setTimeout(() => {
                        crearOTs(values)
                        setSubmitting(false)
                      }, 400)
                    }}
                  >
                    {({values, isSubmitting, handleChange, handleSubmit, errors, isValid}) => (
                      <form className="mt-5">
                        <div className="form-group row">
                          <div className="col-lg-3" key="schedule_date">
                            <div className="form-group">
                              <label htmlFor="start-date">{t('startDate')}</label>
                              <input
                                className="form-control"
                                type="date"
                                placeholder="dd/mm/aaaa"
                                name="schedule_date"
                                min={moment().format('YYYY-MM-DD')}
                                max={moment(values.end_date_scheduled).format('YYYY-MM-DD')}
                                onChange={handleChange}
                                value={values.schedule_date}
                              />
                              {errors.schedule_date && (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block formik-error-message">{errors.schedule_date}</div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-3" key="end_date_scheduled">
                            <div className="form-group">
                              <label htmlFor="end-date+¿">{t('endDate')}</label>
                              <input
                                className="form-control"
                                type="date"
                                placeholder="dd/mm/aaaa"
                                name="end_date_scheduled"
                                min={moment(values.schedule_date).format('YYYY-MM-DD')}
                                onChange={handleChange}
                                value={values.end_date_scheduled}
                              />
                              {errors.end_date_scheduled && (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block formik-error-message">{errors.end_date_scheduled}</div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-3" key="team_id">
                            <div className="form-group">
                              <label htmlFor="team_id">{t('team')}</label>
                              <select
                                className="form-select"
                                name="team_id"
                                onChange={handleChange}
                                value={values.team_id}>
                                <option value={''}>Seleccione una opcion</option>
                                {/*{teams.map(team => (*/}
                                {/*  <option key={team.id} value={team.id}>{team.name}</option>*/}
                                {/*))}*/}
                                {teams.filter(team => team.resolved_in_review !== true).map(team => (
                                  <option key={team.id} value={team.id}>{team.name}</option>
                                ))}
                              </select>
                              {errors.team_id && (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block formik-error-message">{errors.team_id}</div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <button type="submit" className="btn btn-success btn-sm"
                                    disabled={!isValid || isSubmitting || loading !== ''}
                                    onClick={() => handleSubmit()} style={{maxHeight: '40px', marginTop: '25px'}}>
                              <i className="fa fa-save" />{t('createOrders')}
                            </button>
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>
                </React.Fragment>
              </>
            }
          </div>
        </div>
      </LoadingOverlay>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header>
          <Modal.Title>{t('userWhoSolvedIt')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-lg-12">
            {selectedUserInfo && selectedUserInfo.userDetails && selectedUserInfo.boss  &&(

              <div className="form-group">
                <div className="form-group">
                  <b>{t('userWhoSolvedIt')}</b>

                </div>
                <div className="form-group">
                  <label>
                    <b> {t('name') + ': '} </b>{selectedUserInfo?.userDetails?.name || '-'}
                  </label>
                </div>
                <div className="form-group">
                  <label>
                    <b> {t('username') + ': '}</b> {selectedUserInfo?.userDetails?.username || '-'}
                  </label>
                </div>
                <div className="form-group">
                  <label>
                    <b> {t('surname') + ': '}</b>{selectedUserInfo?.userDetails?.surname || '-'}
                  </label>
                </div>
                <div className="form-group">
                  <label>
                    <b> {t('email') + ': '}</b>{selectedUserInfo?.userDetails?.email || '-'}
                  </label>
                </div>
                <br />
                <div className="form-group">
                  <label> Datos del jefe al cual va a ser notificado</label>
                  <div className="form-group">
                    <label>
                      <b> {t('name') + ': '} </b>{selectedUserInfo?.boss?.name || '-'}
                    </label>
                  </div>
                  <div className="form-group">
                    <label>
                      <b> {t('phone') + ': '}</b> {selectedUserInfo?.boss?.manager_phone || '-'}
                    </label>
                  </div>
                  <div className="form-group">
                    <label>
                      <b> {t('username') + ': '}</b> {selectedUserInfo?.boss?.manager_name || '-'}
                    </label>
                  </div>
                  <div className="form-group">
                    <label>
                      <b> {t('email') + ': '}</b>{selectedUserInfo?.boss?.manager_email || '-'}
                    </label>
                  </div>
                  <br />
                </div>
                <div className="form-group">
                  <label>
                    <b> {t('note') + ': '}</b>{t('noteDescription')}
                  </label>
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <button className="btn btn-success btn-sm" onClick={() => createAndCloseWo()}
                  disabled={isLoading}>
            Crear y cerrar orden
          </button>
          <button className="btn btn-danger btn-sm" onClick={() => updateIssueStatus()}>
            volver al estado No reparada
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export {CreateWorkOrders}
